<template>
    <v-card
        :loading="loading"
        class="gt-card"
    >
        <template slot="progress">
            <v-progress-linear
                color="deep-purple"
                height="10"
                indeterminate
            ></v-progress-linear>
        </template>

        <v-card-title class="gt-card__title flex-column align-start">
            <router-link :to="{name: 'venue.public', params: {slug: venue.slug}}">
                {{ venue.name }}
            </router-link>
            <div>
                <v-btn icon @click="toggleFavorite" :color="cardColor">
                    <v-icon>mdi-heart</v-icon>
                </v-btn>
                <v-btn v-if="authCheck && venue.user_id !== authUser.id" icon @click="initChat">
                    <v-icon>mdi-chat</v-icon>
                </v-btn>
            </div>
        </v-card-title>

        <router-link :to="{name: 'venue.public', params: {slug: venue.slug}}">
            <v-img
                class="gt-card__img"
                aspect-ratio="1"
                :src="featuredImage"
            ></v-img>
        </router-link>

        <v-card-text class="gt-card__text">
            <v-row
                v-if="venue.review_score"
                align="center"
                class="mx-0"
                >
                <v-rating
                    :value="venue.review_score"
                    color="amber"
                    dense
                    half-increments
                    readonly
                    size="32"
                ></v-rating>

                <div class="ml-4">
                    {{ venue.review_score }}
                </div>
            </v-row>
            <v-row
                align="center"
                class="mx-0"
                >
                <v-rating
                    :value="venue.price_scale"
                    color="#85bb65"
                    dense
                    empty-icon=""
                    full-icon="mdi-currency-usd"
                    readonly
                    size="32"
                ></v-rating>
            </v-row>

            <div class="my-4">
                <span v-for="(feature, index) in venue.venue_features" :key="`feature-`+feature.id">
                    {{ feature.name }}<span v-if="index < venue.venue_features.length - 1">, </span></span>
            </div>

            <div class="font-weight-regular">{{venue.description}}</div>
        </v-card-text>

        <v-divider class="mx-4" />

        <v-card-title class="gt-card__text">{{ address }}</v-card-title>

        <v-card-text>
            <v-chip-group
                active-class="deep-purple accent-4 white--text"
                column
            >
                <v-chip v-for="foodType in venue.food_types" :key="`food-type-`+foodType.id">
                    {{foodType.name}}
                </v-chip>
            </v-chip-group>
        </v-card-text>

        <v-card-actions>
            <v-btn
                color="deep-purple lighten-2"
                text
            >
                Reserve
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: "VenueCard",
    props: ['venue'],
    computed: {
        cardColor() {
            let user = this.$auth.user();
            if (!user) {
                return 'grey'
            }
            if (user.favoriteVenues.includes(this.venue.id)) {
                return 'red'
            }
            return 'grey'
        },
        authCheck() {
            return this.authUser !== null
        },
        address() {
            let address = this.venue.address

            if (this.venue.city) {
                address = address + ', ' + this.venue.city.name + ', ' + this.venue.city.state_code
            }

            return address
        }
    },
    data: function () {
        return {
            loading: false,
            authUser: this.$auth.user(),
            featuredImage: this.venue.featured_image,
        }
    },
    methods: {
        async toggleFavorite() {
            if (!this.$auth.check()) {
                this.$emit('showLogin')
                return
            }

            await window.axios.post('/api/favorites', {type: 'venue', id: this.venue.id})
            await this.$auth.fetch()
        },
        async initChat() {
            const chat = (await window.axios.get(`/api/chats/init/${this.venue.user_id}`))['data']
            await this.$router.push({name: 'dashboard.chats', params: {id: chat.id}})
        },
    }
}
</script>
