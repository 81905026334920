<template>
    <dashboard-layout>
        <v-row>
            <v-col>
                <v-row>
                    <v-col>
                        <h2>Artists</h2>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col v-for="(artist, index) in artists" :key="`artist-card-${index}`">
                        <artist-card :artist="artist"/>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-row>
                    <v-col>
                        <h2>Venues</h2>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col v-for="(venue, index) in venues" :key="`venue-card-${index}`">
                        <venue-card :venue="venue"/>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </dashboard-layout>
</template>

<script>
import Artist from "@/models/Artist";
import ArtistCard from "@/components/cards/ArtistCard";
import Venue from "@/models/Venue";
import VenueCard from "@/components/cards/VenueCard";
import DashboardLayout from "@/layouts/DashboardLayout";

export default {
    name: "dashboard-favourites",
    components: {DashboardLayout, VenueCard, ArtistCard},
    data() {
        return {
            artists: [],
            venues: [],
            user: this.$auth.user()
        }
    },
    mounted() {
        this.loadArtists()
        this.loadVenues()
    },
    methods: {
        async loadArtists() {
            const ids = this.user['favoriteArtists'];

            if (!ids.length) {
                return
            }

            this.artists = await Artist.custom('artists/public').whereIn('id', ids).with('city').get();
        },
        async loadVenues() {
            const ids = this.user['favoriteVenues'];

            if (!ids.length) {
                return
            }

            this.venues = await Venue.custom('venues/public').whereIn('id', ids).with('city').get()
            this.venues = this.venues.data
        },
    }
}
</script>
