<template>
    <v-card
        :loading="loading"
        class="gt-card"
    >
        <template slot="progress">
            <v-progress-linear
                color="deep-purple"
                height="10"
                indeterminate
            ></v-progress-linear>
        </template>

        <v-card-title class="gt-card__title flex-column align-start">
            <router-link :to="{name: 'artist.public', params: {slug: artist.slug}}">
                {{ artist.name }}
            </router-link>
            <div>
                <v-btn icon @click="toggleFavorite" :color="cardColor">
                    <v-icon>mdi-heart</v-icon>
                </v-btn>
                <v-btn v-if="authCheck && artist.user_id !== authUser.id" icon @click="initChat">
                    <v-icon>mdi-chat</v-icon>
                </v-btn>
            </div>
        </v-card-title>

        <router-link :to="{name: 'artist.public', params: {slug: artist.slug}}">
            <v-img
                class="gt-card__img"
                aspect-ratio="1"
                position="top center"
                :src="avatarUrl"
            ></v-img>
        </router-link>

        <v-card-text class="gt-card__text">
            <v-row
                v-if="artist.review_score"
                align="center"
                class="mx-0 mb-4"
                >
                <v-rating
                    :value="artist.review_score"
                    color="amber"
                    dense
                    half-increments
                    readonly
                    size="32"
                ></v-rating>

                <div class="ml-4">
                    {{ artist.review_score }}
                </div>
            </v-row>

            <p>
                {{artist.city.name}}, {{artist.city.state_code}}
            </p>

            <div class="gt-card__long-text">{{ artist.bio }}</div>
        </v-card-text>

        <v-divider class="mx-4" />

        <v-card-title class="gt-card__text font-italic">Genres</v-card-title>

        <v-card-text>
            <v-chip-group
                active-class="deep-purple accent-4 white--text"
                column
            >
                <v-chip v-for="genre in artist.genres" :key="`genre-`+genre.id">{{genre.name}}</v-chip>
            </v-chip-group>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "ArtistCard",
    props: ['artist'],
    computed: {
        cardColor() {
            let user = this.$auth.user();
            if (!user) {
                return 'grey'
            }
            if (user.favoriteArtists.includes(this.artist.id)) {
                return 'red'
            }
            return 'grey'
        },
        authCheck() {
            return this.authUser !== null
        }
    },
    data: function () {
        return {
            loading: false,
            authUser: this.$auth.user(),
            avatarUrl: this.artist.avatar_url,

        }
    },
    methods: {
        async toggleFavorite() {
            if (!this.$auth.check()) {
                this.$emit('showLogin')
                return
            }

            await window.axios.post('/api/favorites', {type: 'artist', id: this.artist.id})
            await this.$auth.fetch()
        },
        async initChat() {
            const chat = (await window.axios.get(`/api/chats/init/${this.artist.user_id}`))['data']
            await this.$router.push({name: 'dashboard.chats', params: {id: chat.id}})
        }
    },
}
</script>
